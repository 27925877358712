import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  Inject,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { NavFacade } from "./nav/nav.facade";
import { MainFacade } from "./pages/main.facade";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private renderer: Renderer2,
    public location: Location,
    @Inject(DOCUMENT) document,
    private router: Router,
    private navFacade: NavFacade,
    private mainFacade:MainFacade
  ) {
    


   
    
  }


  

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    //this.myModal.nativeElement.play();
  }

}
