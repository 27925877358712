import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavFacade } from 'src/app/nav/nav.facade';

@Component({
  selector: 'app-species',
  templateUrl: './species.component.html',
  styleUrls: ['./species.component.scss']
})
export class SpeciesComponent implements OnInit, OnDestroy {
  public login$: Observable<any>|undefined;
  public error$: Observable<any>|undefined;
  public nfts$: Observable<any>|undefined;
  public showNfts$: Observable<any>|undefined;
  public token$: Observable<any>|undefined;
  private destroy$ = new Subject();
  progressPos:any = 0;
  constructor(private navFacade:NavFacade) { }

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }



  setObservables() {
    this.login$ = this.navFacade.login$;
    this.error$ = this.navFacade.error$;
    this.nfts$ = this.navFacade.nfts$;
    this.showNfts$ = this.navFacade.showNfts$
    this.token$ = this.navFacade.token$;
    this.token$ 
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){

          this.setProgress(message);
      }
    });

  }

  async setProgress(message){
        let bar = document.getElementById('progress').getBoundingClientRect().width ? document.getElementById('progress').getBoundingClientRect().width : 0;

          while(bar === 0){
            await this.delay(500)
            bar = document.getElementById('progress').getBoundingClientRect().width ? document.getElementById('progress').getBoundingClientRect().width : 0;
        
          }

          if(message === 0){
            this.progressPos = 0;
          }
          else if(message <= 100){
        
            this.progressPos = (Number(message)/100) *(bar/22);
          }
          else if(message <= 500){

            this.progressPos = (Number(message)/500) *(bar/3.7);
          }
          else if(message <= 1000){

            this.progressPos = (Number(message)/1000) *(bar/2.04);
          }
          else if(message <= 5000){
            this.progressPos = (Number(message)/5000) *(bar/1.395);
          }
          else if(message <= 10000){
          
            this.progressPos = (Number(message)/11000) *bar+6;

          }
          else{
            this.progressPos = bar-5;
          }
          
  }

  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }

  login(){
    this.navFacade.login(null);
  }

}


