import { Component, OnInit, TemplateRef, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Moralis from 'moralis';
import { Store } from '@ngrx/store';
import { MarketState } from '../lib/+state/market.reducer';
import { Observable, Subject } from 'rxjs';
import { NavFacade } from './nav.facade';
import { takeUntil} from 'rxjs/operators';
import { threadId } from 'worker_threads';
import { AuthService } from '../lib/services/auth.service';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { MainFacade } from '../pages/main.facade';
var moment = require('moment-timezone');
//import { MarketComponent } from '../pages/market/market.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit, OnDestroy {
  public login$: Observable<any>|undefined;
  public error$: Observable<any>|undefined;
  public nfts$: Observable<any>|undefined;
  public balance$: Observable<any>|undefined;
  private destroy$ = new Subject();

  showMobileMenu: boolean = false;
  //Modal Related
  walletSplit: any;
  walletFull: any;
  profileDropDown: boolean = false;

  showNfts:boolean = false;
  
  constructor(@Inject(DOCUMENT) private doc: Document, private store: Store<MarketState>, 
  private navFacade:NavFacade, private auth:AuthService, private mainfacade:MainFacade, private router:Router) {
    document.addEventListener("mousedown", (ele):void => {      

      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('profile') < 0 && (ele.target as Element).className.indexOf('profile') < 0){
        this.profileDropDown = false;
      }

      if(ele?.target && (ele.target as Element).className && (ele.target as Element).className.indexOf('mob') >= 0 && (ele.target as Element).className.indexOf('mob') >= 0){
        this.showMobileMenu = false;
      }
      
    });

  }

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setObservables() {
    this.login$ = this.navFacade.login$;
    this.error$ = this.navFacade.error$;
    this.nfts$ = this.navFacade.nfts$

    this.login$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.walletFull = message
        this.walletSplit = message.slice(0, 4) +" "+ message.slice(5, 7) +"..." + message.slice(38, 42);
      }

    });

    //this.login();

  }

  

  login(){
    this.navFacade.login(null);
  }

  

  logout() {
    this.navFacade.logout();
    
  }

  

}
