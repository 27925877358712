import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ApiService } from './api.service';
import { createClient } from 'urql'
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class MainService {
  constructor(private apiService: ApiService) {}

  getBalance(wallet){
    return this.apiService.post('/api/tokens',{wallet:wallet});
  }

}