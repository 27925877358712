import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import * as MarketActions from './market.action';
import * as moment from 'moment';
import { stat } from 'fs';
export const MarketFeatureKey = 'Market';

export interface MarketState {
  readonly [MarketFeatureKey]: any;
}

export const MarketInitialState: any = {
  error: '',
  user:'',
  loading:false,
  login:'',
  nfts:[],
  showNfts:[],
  tokens:0
}

const reducer = createReducer(
    MarketInitialState,
//UNIVERSAL
  immerOn(MarketActions.loadingStart, (state:any, action:any) => {
    state.loading = true;
  }),

  immerOn(MarketActions.loginSuccess, (state:any, action:any) => {
    state.login = action.wallet;
    state.error = '';
  }),

  immerOn(MarketActions.loginFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.login = '';
  }),

  immerOn(MarketActions.getTokensSuccess, (state:any, action:any) => {
    state.tokens = action.tokens?.Amount ? action.tokens?.Amount : 0;
    state.error = '';
  }),

  immerOn(MarketActions.getTokensFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.tokens = '';
  }),

  immerOn(MarketActions.logoutSuccess, (state:any, action:any) => {
    state.login = '';
    state.tokens = 0;
    state.nfts=[];
    state.showNfts=[]
    
  }),

  immerOn(MarketActions.logoutFail, (state:any, action:any) => {
    state.error=action.error.message;

  }),


  immerOn(MarketActions.getNftsSuccess, (state:any, action:any) => {
    state.showNfts=action.Nfts.showNfts;
    state.nfts = action.Nfts.nfts;
    state.error = '';
  }),

  immerOn(MarketActions.getNftsFail, (state:any, action:any) => {
    state.error=action.error.message;
    state.nfts = '';
    state.dwc = false;
    state.dark = false;
    state.pg = false;
  }),

  immerOn(MarketActions.setNavSuccess, (state:any, action:any) => {
    state.isNav=action.isNav;
   
  }),

);

export function MarketReducer(state: any, action: Action): any {
    return reducer(state, action);
}