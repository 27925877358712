import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ApiService } from './api.service';
import { createClient } from 'urql'
import { HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
//import {environment} from '../../../environments/environment.dev';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  constructor(private apiService: ApiService, private cookie:CookieService) {}

  /*decryptToken(token:string):boolean{
    const decode = decodeURIComponent(token);
    //var crypt = bcrypt.compareSync("fuckinsecure", decode);
    return decode === 'we12secure';
  }
  
  discordAuth(){
    const redirect = encodeURIComponent(environment.prod+'');
    sessionStorage.setItem('state', 'we12secure');
    let state = encodeURIComponent('we12secure');
    window.location.href = environment.discordauth+'?response_type=code&client_id='+environment.discordClient+'&scope=identify%20guilds&state='+state+'&redirect_uri='+redirect+'&prompt=consent';
  }
  
  getDiscordData(token:string, wallet:string){

    return this.apiService.post("/Auth/Discord", {token:token, wallet:wallet, urlType:environment.urlType});
  }

  
  getDiscordUser(token:string){
    const httpOptions = new HttpHeaders({
      'Authorization': 'Bearer '+this.cookie.getCookie('token')
      });
    return this.apiService.getCustom("https://discord.com/api/v8/users/@me",httpOptions);
  }*/
}