import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import {MarketQuery} from '../lib/+state/market.selector';
import * as MarketActions from '../lib/+state/market.action';
import {MarketState} from '../lib/+state/market.reducer';

@Injectable({ providedIn: 'root' })
export class MainFacade {
    error$ = this.store.select(MarketQuery.getError);
    balance$ = this.store.select(MarketQuery.getTokenBalance);
    nfts$ = this.store.select(MarketQuery.getNfts);
    
    constructor(private store: Store<MarketState>) {}

    showNav(isNav:boolean){
        this.store.dispatch(MarketActions.setNav({data:isNav}))
    }

    
}