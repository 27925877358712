import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import {MarketQuery} from '../lib/+state/market.selector';
import * as MarketActions from '../lib/+state/market.action';
import {MarketState} from '../lib/+state/market.reducer';

@Injectable({ providedIn: 'root' })
export class NavFacade {
    error$ = this.store.select(MarketQuery.getError);
    login$ = this.store.select(MarketQuery.login);
    nfts$ = this.store.select(MarketQuery.getNfts);
    showNfts$ = this.store.select(MarketQuery.getShowNfts);
    isNav$ = this.store.select(MarketQuery.getNav);
    token$ =this.store.select(MarketQuery.getTokenBalance)
    constructor(private store: Store<MarketState>) {}
    
    login(provider:string){
        this.store.dispatch(MarketActions.login({provider:provider}));
    }

    logout(){
        this.store.dispatch(MarketActions.logout({data:null}));
    }



    
    
}