import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { HomeComponent } from './home/home.component';
import { SpeciesComponent } from './species/species.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CarouselModule,
    CountdownModule
  ],
  declarations: [
    HomeComponent,
    SpeciesComponent
  ],
  exports: [

  ],
  providers: [
  ]
})
export class PagesModule { }
