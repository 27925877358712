import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MarketFeatureKey } from './market.reducer';

const getMarketFeatureKey = createFeatureSelector<any>(MarketFeatureKey);

export const getError = createSelector(
    getMarketFeatureKey,
(state: any) => {
  return state.error
}
);


export const login = createSelector(
    getMarketFeatureKey,
(state: any) => {
  return state.login
}
);

export const getNfts = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.nfts
}
);

export const getNav = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.isNav
}
);

export const getShowNfts = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.showNfts
}
);

export const getTokenBalance = createSelector(
  getMarketFeatureKey,
(state: any) => {
  return state.tokens
}
);


export const MarketQuery = {
    getMarketFeatureKey,
    getError,
    login,
    getNfts,
    getNav,
    getShowNfts,
    getTokenBalance
};