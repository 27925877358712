import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { PagesModule } from "./pages/pages.module";
import { NavComponent } from './nav/nav.component';
import { environment } from "src/environments/environment.dev";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MarketEffects } from "./lib/+state/market.effects";
import { MarketFeatureKey, MarketInitialState, MarketReducer } from "./lib/+state/market.reducer";
import { NavFacade } from "./nav/nav.facade";
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { ToastService, AngularToastifyModule } from 'angular-toastify';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    PagesModule,
    ReactiveFormsModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(MarketFeatureKey, MarketReducer, {
      initialState: MarketInitialState,
    }),
    EffectsModule.forFeature([MarketEffects]),
    StoreDevtoolsModule.instrument(),
    CountdownModule,
    AngularToastifyModule
    
  ],
  providers: [MarketEffects, NavFacade, ToastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
