import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { SpeciesComponent } from "./pages/species/species.component";


const routes: Routes = [
  { path: "", redirectTo: "species", pathMatch: "full" },
  { path: "species", component: SpeciesComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule { }
