import { createAction, props } from '@ngrx/store';

//UNIVERSAL
export const loadingStart = createAction(
    '[Market] LOADING_STARTED',
    props<{ data:boolean }>()
);

export const setNav = createAction('[Market] SET_NAV',
  props<{data: any }>()
);

export const setNavSuccess = createAction('[Market] SET_NAV_SUCCESS',
  props<{isNav: any }>()
);

export const getTokens = createAction('[Market] GET_TOKENS',
  props<{address: any }>()
);

export const getTokensFail = createAction('[Market] GET_TOKENS_FAIL',
  props<{error: any }>()
);

export const getTokensSuccess = createAction('[Market] GET_TOKENS_SUCCESS',
  props<{tokens:any }>()
);

export const login = createAction('[Market] GET_LOGIN',
  props<{provider: any }>()
);

export const loginFail = createAction('[Market] GET_LOGIN_FAIL',
  props<{error: any }>()
);

export const loginSuccess = createAction('[Market] GET_LOGIN_SUCCESS',
  props<{wallet: any }>()
);

export const logout = createAction('[Market] GET_LOGOUT',
  props<{data: any }>()
);

export const logoutFail = createAction('[Market] GET_LOGOUT_FAIL',
  props<{error: any }>()
);

export const logoutSuccess = createAction('[Market] GET_LOGOUT_SUCCESS',
  props<{wallet: any }>()
);

  export const getNfts = createAction(
    '[Market] GET_NFTS',
    props<{ data:any }>()
    );
  
  export const getNftsSuccess = createAction(
      '[Market] GET_NFTS_SUCCESS',
      props<{ Nfts: any }>()
    );
  
  export const getNftsFail = createAction(
      '[Market] GET_NFTS_FAIL',
      props<{ error: any }>()
  );


  

  
