import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as MarketActions from './market.action';
import { Action } from 'rxjs/internal/scheduler/Action';
import { MarketState } from './market.reducer';
import { MainService } from '../services/main.service';
import { Web3Service } from '../services/web3.service';
import { AuthService } from '../services/auth.service';
import { CookieService } from '../services/cookie.service';
import { ToastService } from 'angular-toastify';
var moment = require('moment-timezone');


@Injectable()
export class MarketEffects {
  tutorial:number=0;
   constructor(
    private actions$: Actions,
    private store: Store<MarketState>,
    private mainService: MainService,
    private web3:Web3Service,
    private authService:AuthService,
    private cookie:CookieService,
    private toastr:ToastService
  ) {}

  login = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.login),
      concatMap((action: any) => from(this.web3.initWeb3()).pipe(
        map((data: any) =>{
            if(data.error){
                this.toastr.error("ERROR OCCURED ON WALLET CONNECT.");
                return MarketActions.loginFail({error:{message:"ERROR OCCURED ON WALLET CONNECT."}});
            }
            
            this.store.dispatch(MarketActions.getNfts({data:data}));
            this.store.dispatch(MarketActions.getTokens({address:data}));
            return MarketActions.loginSuccess({wallet: data});
        }),
        catchError((error) => {
          
          return of(MarketActions.loginFail({error}));
        })

        )
      )
    );
    }
  );

  tokenBalance = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getTokens),
      concatMap((action: any) => this.mainService.getBalance(action.address).pipe(
        map((data: any) =>{
            
            return MarketActions.getTokensSuccess({tokens: data.tokens});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.getTokensFail({error}));
        })

        )
      )
    );
    }
  );

  logout = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.logout),
      concatMap((action: any) => from(this.web3.logout()).pipe(
        map((data: any) =>{
            if(data.error){
              this.toastr.error("ERROR OCCURED ON WALLET DISCONNECT.");
                return MarketActions.logoutFail({error:{message:"ERROR OCCURED ON WALLET DISCONNECT."}});
            }
            //this.store.dispatch(MarketActions.login({provider:null}));
            return MarketActions.logoutSuccess({wallet: data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.loginFail({error}));
        })

        )
      )
    );
    }
  );

  getNFTs = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.getNfts),
      concatMap((action: any) => from(this.web3.getNFTsWallet(action.data)).pipe(
        map((data: any) =>{
            if(data.error){
              this.toastr.error("ERROR OCCURED GETTING NFTS.");
                return MarketActions.getNftsFail({error:{message:"ERROR OCCURED GETTING NFTS."}});
            }
     
            return MarketActions.getNftsSuccess({Nfts: data});
        }),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return of(MarketActions.loginFail({error}));
        })

        )
      )
    );
    }
  );

  setNav = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarketActions.setNav),
      map((action: any) => {
        return MarketActions.setNavSuccess({isNav:action.data});
      }
    )
    )
  });


}