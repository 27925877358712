import { Injectable  } from '@angular/core';
import { Component } from '@angular/core';
import Moralis from 'moralis';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { checkAddressChecksum } from 'web3-utils';
//import {environment} from '../../../environments/environment.dev';
@Injectable({
  providedIn: 'root'
})
export class Web3Service {
  provider:any;
  web3:any;
  web3Modal:any;
  wallet:any;
  constructor(private router:Router, private toast:ToastService) {
   
  }

  async initWeb3(){

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "68bbfa6dd6594f328012419c5b654b2f" // required
        }
      }
    };
    
    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    try {
      this.provider = await this.web3Modal.connect();
      this.web3  = new Web3(this.provider);
      let network = await this.web3.eth.net.getNetworkType();
   

      //Display warning if on the wrong network
      if(network !== 'main'){
        this.toast.info("Please switch to the Ethereum Mainnet network.");
        await this.web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex('1') }]
        });
        network = await this.web3.eth.net.getNetworkType();
      
        //return;
      }

      let accounts = await this.web3.eth.getAccounts();
      
      let walletSet = accounts[0];
      
      if(!localStorage.getItem('sig')){
        let message = 'This is used to verify that you own this account for R Planet and secures that any actions on the site are made by you alone.';
        let msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
        let sig = await this.web3.currentProvider.request({
          method: 'personal_sign',
          params: [msg, walletSet, environment.token]
        });
   
        //console.log(sig)
        localStorage.setItem('sig', sig);
        //let rec = await this.web3.eth.accounts.recover(walletSet,sig);
        //console.log(rec)
      }
      else {
        let message = 'This is used to verify that you own this account for R Planet and secures that any actions on the site are made by you alone.';
        let msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
        let rec = await this.web3.eth.accounts.recover(msg,localStorage.getItem('sig'));
        if(rec.toLowerCase() !== walletSet.toLowerCase()){
          let sig = await this.web3.currentProvider.request({
            method: 'personal_sign',
            params: [msg, walletSet, environment.token]
          });
          
          localStorage.setItem('sig', sig);
        }
      }
   
      return walletSet;
    }
    catch(err){
      console.log(err)
      return {error:true,message:err.message};
    }
  }

  async logout(){
    try {
      this.web3Modal.clearCachedProvider();
      localStorage.removeItem('code');
      localStorage.removeItem('sig');
      return {};
    }
    catch(err){
      console.log(err)
      return {error:true,message:err.message};
    }
  }

  async getNFTsWallet(address){
    try{
      let ethNFTs = [];
      //let contract = new this.web3.eth.Contract(stabi, environment.stjira);
      let data = await this.getMoralisData(address, null) as any;
        ethNFTs = ethNFTs.concat(data.result);
        let count1 = 0;
        while(data.cursor){
          data = await this.getMoralisData(address, data.cursor) as any;
          ethNFTs = ethNFTs.concat(data.result);
          count1++;
        }

      
      let nfts=[];
      //group by contract
      for(let nft of ethNFTs){
        //Gen1
        if(nft.token_address.toString().toLowerCase() === ('0x656D34A8309363302e46De99853F4cef30B85A1D').toString().toLowerCase()){
          let nftObj = {
            Project:'RPlanet Genesis',
            Image:'https://rplanet-nft.s3.amazonaws.com/images/'+nft.token_id+'.png',
            token_id:nft.token_id,
            token_address:nft.token_address,
            Name:'RPlanet #'+nft.token_id,
            Format:'image'
          }
          nfts.push(nftObj)
        }
      
      };
      let showNfts = [];
      nfts.forEach((nf)=>{
        if(showNfts.length <= 4){
          showNfts.push(nf)
        }

      })

      return { nfts:nfts, showNfts:showNfts };

    }
    catch(err){

      return {error:true,message:err.message};

    }
  }

  async getMoralisData(token_address, cursor){
    try{
      let url = 'https://deep-index.moralis.io/api/v2/'+token_address+'/nft';
      if(cursor){
        url += '?cursor='+cursor;
      }
      let response = await fetch(url, {
        headers: new Headers({'x-api-key': 'komhjNL5MNJS8cGgsLeBSTtQcQDSRShS9cAWwPIyLzno7t9vzdgH7rTqsUE8gJ8x'})
      });
      let data = null;

      if(response) {
        data = await response.json();

      }
      return data;
    }
    catch(err){

    }
  }

  async getImage(uri){
    try{
      let url = uri.replace('ipfs://','https://ipfs.io/ipfs/');
      
      let response = await fetch(url);
      let data = null;

      if(response) {
        data = await response.json();
      
      }
      return data;
    }
    catch(err){
      console.log(err)
    }
  }


}
