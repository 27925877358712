import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MainFacade } from '../main.facade';
import { CountdownConfig } from 'ngx-countdown';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastService } from 'angular-toastify';
import { takeUntil} from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $: any;
var moment = require('moment-timezone');
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  

  constructor(private mainfacade:MainFacade, private sanitizer: DomSanitizer, private toastService: ToastService, private router:Router) { 

    
  }

 

 
  ngOnInit(): void {
   
  }



}
