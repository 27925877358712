import { domain, clientId } from '../auth_config.json';

export const environment = {
  production: true,
  urlType:"PROD",
  //endpoint:"http://localhost:5000",
  //base:"http://localhost:8546/",
  endpoint:"https://rplanet-dashboard.xyz",
  //prod:"http://localhost:8546/#/home",
  //base:"",
  //prod:"",
  token:"aihsiodfhasohaghfuabfuvabsohiahuvaudfihsois",
  //discordauth:"",
  //discordClient:"",
  //siteKey:"",
  
};
